(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
    typeof define === 'function' && define.amd ? define(['exports'], factory) :
    (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.html2pdfmake = {}));
})(this, (function (exports) { 'use strict';

    const META = Symbol('__HTML2PDFMAKE');
    const NODE = 'NODE';
    const UID = 'UID';
    const END_WITH_NEWLINE = 'END_WITH_NEWLINE';
    const START_WITH_NEWLINE = 'START_WITH_NEW_LINE';
    const IS_NEWLINE = 'IS_NEWLINE';
    const START_WITH_WHITESPACE = 'START_WITH_WHITESPACE';
    const END_WITH_WHITESPACE = 'END_WITH_WHITESPACE';
    const IS_WHITESPACE = 'IS_WHITESPACE';
    const IS_INPUT = 'IS_INPUT';
    const MARGIN = 'MARGIN';
    const PADDING = 'PADDING';
    const BORDER = 'BORDER';
    const POST_HANDLER = 'POST_HANDLER';
    const PRE_HANDLER = 'PRE_HANDLER';
    const PDFMAKE = 'PDFMAKE';
    const STYLE = 'STYLE';
    const CLASSES = 'CLASSES';
    const POS_TOP = 1; // CSS 0
    const POS_RIGHT = 2; // CSS 1
    const POS_BOTTOM = 3; // CSS 2
    const POS_LEFT = 0; // CSS 3
    const getPatterns = () => ({
        fill: {
            boundingBox: [1, 1, 4, 4],
            xStep: 1,
            yStep: 1,
            pattern: '1 w 0 1 m 4 5 l s 2 0 m 5 3 l s'
        }
    });

    const globalStyles = () => ({
        ':root': {
            'font-size': '16px',
            'line-height': 1.2
        },
        h1: {
            'font-size': '2em',
            'margin-top': '21.44px',
            'margin-bottom': '21.44px',
            'font-weight': 'bold'
        },
        h2: {
            'font-size': '1.5em',
            'margin-top': '19.92px',
            'margin-bottom': '19.92px',
            'font-weight': 'bold'
        },
        h3: {
            'font-size': '1.17em',
            'margin-top': '18.72px',
            'margin-bottom': '18.72px',
            'font-weight': 'bold'
        },
        h4: {
            'margin-top': '21.28px',
            'margin-bottom': '21.28px',
            'font-weight': 'bold'
        },
        h5: {
            'font-size': '.83em',
            'margin-top': '22.17px',
            'margin-bottom': '22.17px',
            'font-weight': 'bold'
        },
        h6: {
            'font-size': '.67em',
            'margin-top': '24.97px',
            'margin-bottom': '24.97px',
            'font-weight': 'bold'
        },
        b: {
            'font-weight': 'bold'
        },
        strong: {
            'font-weight': 'bold'
        },
        i: {
            'font-style': 'italic',
        },
        em: {
            'font-style': 'italic'
        },
        s: {
            'text-decoration': 'line-through'
        },
        del: {
            'text-decoration': 'line-through'
        },
        sub: {
            'font-size': '22px',
            'vertical-align': 'sub'
        },
        sup: {
            'font-size': '13px',
            'vertical-align': 'super'
        },
        small: {
            'font-size': '13px'
        },
        u: {
            'text-decoration': 'underline'
        },
        ul: {
            'margin-top': '16px',
            'margin-bottom': '16px',
            'padding-left': '20px'
        },
        ol: {
            'margin-top': '16px',
            'margin-bottom': '16px',
            'padding-left': '20px'
        },
        p: {
            'margin-top': '16px',
            'margin-bottom': '16px'
        },
        figure: {
            padding: '16px 40px'
        },
        tr: {
        /*  margin: '4px 0'*/
        },
        th: {
            'font-weight': 'bold',
            'text-align': 'center'
        },
        a: {
            color: '#0000ee',
            'text-decoration': 'underline'
        },
        hr: {
            'border': '1px solid #9a9a9a',
            'border-bottom': '0',
            'border-left': '0',
            'border-right': '0',
            margin: '8px 0'
        },
        pre: {
            'white-space': 'pre'
        }
    });

    const defaultConfig = () => ({
        globalStyles: globalStyles(),
        styles: {},
        collapseMargin: true,
        collapseWhitespace: true,
        render: (el) => el,
        document: () => window.document,
        parseCss: () => ({}),
        defaultFont: 'Roboto'
    });

    /**
     * @description Method to check if an item is an object. Date and Function are considered
     * an object, so if you need to exclude those, please update the method accordingly.
     * @param item - The item that needs to be checked
     * @return {Boolean} Whether or not @item is an object
     */
    const isObject = (item) => {
        return (item === Object(item) && !Array.isArray(item));
    };
    /**
     * @description Method to perform a deep merge of objects
     * @param {Object} target - The targeted object that needs to be merged with the supplied @sources
     * @param {Array<Object>} sources - The source(s) that will be used to update the @target object
     * @return {Object} The final merged object
     */
    const merge = (target, ...sources) => {
        // return the target if no sources passed
        if (!sources.length) {
            return target;
        }
        const result = target;
        if (isObject(result)) {
            for (let i = 0; i < sources.length; i += 1) {
                if (isObject(sources[i])) {
                    const elm = sources[i];
                    Object.keys(elm).forEach(key => {
                        if (isObject(elm[key])) {
                            if (!result[key] || !isObject(result[key])) {
                                result[key] = {};
                            }
                            merge(result[key], elm[key]);
                        }
                        else {
                            result[key] = elm[key];
                        }
                    });
                }
            }
        }
        return result;
    };

    class Context {
        config;
        images = {};
        _styles = null;
        pageStyles = {};
        constructor(config) {
            this.config = config;
        }
        get styles() {
            if (this._styles) {
                return this._styles;
            }
            this._styles = merge({}, this.config.globalStyles || {}, this.pageStyles, this.config.styles);
            return this._styles;
        }
        setPageStyles(styles) {
            this.pageStyles = styles;
        }
        get fonts() {
            return this.config.fonts;
        }
    }
    const createContext = (_config = defaultConfig()) => {
        const config = {
            ...defaultConfig(),
            ..._config
        };
        return new Context(config);
    };

    const isNotText = (item) => typeof item !== 'string';
    const isColgroup = (item) => item?.[META]?.[NODE]?.nodeName === 'COLGROUP';
    const isImage = (item) => 'image' in item;
    const isTable = (item) => 'table' in item;
    const isTextArray = (item) => !!item && typeof item !== 'string' && 'text' in item && Array.isArray(item.text);
    const isTextSimple = (item) => typeof item !== 'string' && 'text' in item && typeof item.text === 'string';
    const isTextOrLeaf = (item) => 'text' in item || typeof item === 'string';
    const isList = (item) => 'ul' in item || 'ol' in item;
    const isTdOrTh = (item) => item[META]?.[NODE]?.nodeName === 'TD' || item[META]?.[NODE]?.nodeName === 'TH' || item[META]?.[NODE]?.nodeName === 'COL';
    const isElement = (el) => el.nodeType === 1;
    const isNode = (el) => el.nodeType === 3 || el.nodeType === 8;
    const isCollapsable = (item) => typeof item !== 'undefined' && typeof item !== 'string' && ('stack' in item || 'ul' in item || 'ol' in item || 'table' in item) && 'margin' in item;

    const getChildItems = (item) => {
        if (typeof item === 'string') {
            return [];
        }
        if ('stack' in item) {
            return item.stack;
        }
        if ('text' in item && typeof item.text !== 'string') {
            return item.text;
        }
        if ('table' in item) {
            return item.table.body
                .flatMap(tr => tr)
                .filter(isNotText);
        }
        if ('ul' in item) {
            return item.ul;
        }
        if ('ol' in item) {
            return item.ol;
        }
        return [];
    };

    const toUnit = (value, rootPt = 12) => {
        // if it's just a number, then return it
        if (typeof value === 'number') {
            return isFinite(value) ? value : 0;
        }
        const val = Number(parseFloat(value));
        if (isNaN(val)) {
            return 0;
        }
        const match = ('' + value).trim().match(/(pt|px|r?em|cm)$/);
        if (!match) {
            return val;
        }
        switch (match[1]) {
            case 'em':
            case 'rem':
                return val * rootPt;
            case 'px':
                // 1px = 0.75 Point
                return Number((val * 0.75).toFixed(2));
            case 'cm':
                return Number((val * 28.34).toFixed(2));
            case 'mm':
                return Number((val * 10 * 28.34).toFixed(2));
            default:
                return val;
        }
    };
    const getUnitOrValue = (value) => typeof value === 'string' && (value.indexOf('%') > -1 || value.indexOf('auto') > -1)
        ? value
        : toUnit(value);
    const toUnitOrValue = (value) => getUnitOrValue(value);
    const toUnitsOrValues = (value) => value.map(v => getUnitOrValue(v));
    const expandValueToUnits = (value) => {
        const values = toUnitsOrValues(value.split(' ')
            .map(v => v.trim())
            .filter(v => v));
        if (values === null || !Array.isArray(values)) {
            return null;
        }
        // values[0] = top
        // values[1] = right
        // values[2] = bottom
        // values[3] = left
        // pdfmake use left, top, right, bottom, ||  [horizontal, vertical]
        // css use top, right, bottom, left
        if (values.length === 1 && values[0] !== null) {
            return [values[0], values[0], values[0], values[0]];
        }
        else if (values.length === 2) {
            // topbottom leftright
            return [values[1], values[0], values[1], values[0]];
        }
        else if (values.length === 3) {
            // top bottom leftright
            return [values[2], values[0], values[2], values[1]];
        }
        else if (values.length === 4) {
            return [values[3], values[0], values[1], values[2]];
        }
        return null;
    };

    const handleColumns = (item) => {
        const childItems = getChildItems(item);
        const spaceBetween = item[META][STYLE]?.['justify-content'] === 'space-between';
        const defaultWidth = spaceBetween ? '*' : 'auto';
        return {
            columns: childItems
                .flatMap((subItem) => {
                if ('text' in subItem && Array.isArray(subItem.text)) {
                    return subItem.text
                        .filter(childItem => !childItem[META]?.[IS_WHITESPACE])
                        .map(text => {
                        const width = toUnitOrValue(text[META]?.[STYLE]?.width || defaultWidth) || defaultWidth;
                        return (typeof text === 'string') ? {
                            text,
                            width
                        } : {
                            ...text,
                            width
                        };
                    });
                }
                return {
                    stack: [].concat(subItem),
                    width: toUnitOrValue(subItem[META]?.[STYLE]?.width || defaultWidth) || defaultWidth
                };
            }),
            columnGap: 'columnGap' in item ? item.columnGap : 0,
            [META]: item[META]
        };
    };

    const handleImg = (image) => {
        if (isImage(image) && typeof image.width === 'number' && typeof image.height === 'number') {
            image.fit = [image.width, image.height];
        }
        return image;
    };

    const computeMaxValue = (topBottom, leftRight, trIndex, tdIndex, points) => {
        topBottom[trIndex] = topBottom[trIndex] || [0, 0];
        leftRight[tdIndex] = leftRight[tdIndex] || [0, 0];
        topBottom[trIndex] = [
            Math.max(topBottom[trIndex][0], points?.[POS_TOP] || 0),
            Math.max(topBottom[trIndex][1], points?.[POS_BOTTOM] || 0)
        ];
        leftRight[tdIndex] = [
            Math.max(leftRight[tdIndex][0], points?.[POS_LEFT] || 0),
            Math.max(leftRight[tdIndex][1], points?.[POS_RIGHT] || 0)
        ];
    };
    const handleTable = (item) => {
        if (isTable(item)) {
            const bodyItem = item.table.body[0]?.[0];
            const tableItem = bodyItem && typeof bodyItem !== 'string' && 'table' in bodyItem ? bodyItem : null;
            if (!tableItem) {
                return item;
            }
            const collapseBorder = item?.[META]?.[STYLE]?.['border-collapse'] === 'collapse';
            const innerTable = tableItem.table;
            const paddingsLeftRight = [];
            const paddingsTopBottom = [];
            const borderTopBottom = [];
            const borderLeftRight = [];
            innerTable.body
                .forEach((row, trIndex) => {
                row.forEach((column, tdIndex) => {
                    if (column[META]?.[PADDING]) {
                        computeMaxValue(paddingsTopBottom, paddingsLeftRight, trIndex, tdIndex, column[META]?.[PADDING]);
                    }
                    if (column[META]?.[BORDER]) {
                        computeMaxValue(borderTopBottom, borderLeftRight, trIndex, tdIndex, column[META]?.[BORDER]);
                    }
                    if (typeof column !== 'string') {
                        column.style = column.style || [];
                        const nodeName = column[META]?.[NODE]?.nodeName === 'TH' ? 'th' : 'td';
                        column.style.push(tdIndex % 2 === 0 ? nodeName + ':nth-child(even)' : nodeName + ':nth-child(odd)');
                        column.style.push(trIndex % 2 === 0 ? 'tr:nth-child(even)' : 'tr:nth-child(odd)');
                    }
                });
            });
            // Padding fix
            innerTable.body
                .forEach((row, trIndex) => {
                row.forEach((column, tdIndex) => {
                    if (!column[META]?.[PADDING]?.[POS_LEFT] && typeof column !== 'string' && paddingsLeftRight[tdIndex]) {
                        column.margin = column.margin || [0, 0, 0, 0];
                        if (column.margin) {
                            column.margin[POS_LEFT] = column.margin[POS_LEFT] - paddingsLeftRight[tdIndex][0];
                        }
                    }
                    if (!column[META]?.[PADDING]?.[POS_TOP] && typeof column !== 'string' && paddingsTopBottom[trIndex]) {
                        column.margin = column.margin || [0, 0, 0, 0];
                        if (column.margin) {
                            column.margin[POS_TOP] = column.margin[POS_TOP] - paddingsTopBottom[trIndex][0];
                        }
                    }
                });
            });
            const tableLayout = {
                defaultBorder: false
            };
            const defaultPadding = 0.75;
            tableLayout.paddingTop = (i) => paddingsTopBottom[i]?.[0] ?? defaultPadding;
            tableLayout.paddingBottom = (i) => paddingsTopBottom[i]?.[1] ?? defaultPadding;
            tableLayout.paddingLeft = (i) => paddingsLeftRight[i]?.[0] ?? defaultPadding;
            tableLayout.paddingRight = (i) => paddingsLeftRight[i]?.[1] ?? defaultPadding;
            if (borderTopBottom.length) {
                tableLayout.hLineWidth = (i) => {
                    const even = i % 2 === 0;
                    if (collapseBorder) {
                        return borderTopBottom[i]?.[0] ?? borderTopBottom[i - 1]?.[1] ?? 0;
                    }
                    if (i === 0) {
                        return 0;
                    }
                    const index = even ? i - 1 : i;
                    return borderTopBottom[index]?.[even ? 1 : 0] ?? 0;
                };
            }
            if (borderLeftRight.length) {
                tableLayout.vLineWidth = (i) => {
                    const even = i % 2 === 0;
                    if (collapseBorder) {
                        return borderLeftRight[i]?.[0] ?? borderLeftRight[i - 1]?.[1] ?? 0;
                    }
                    if (i === 0) {
                        return 0;
                    }
                    const index = even ? i - 1 : i;
                    return borderLeftRight[index]?.[even ? 1 : 0] ?? 0;
                };
            }
            tableItem.layout = tableLayout;
            if (collapseBorder) {
                item.layout = {
                    ...(typeof item.layout !== 'string' ? item.layout : {}),
                    paddingLeft: () => 0,
                    paddingRight: () => 0,
                    paddingTop: () => 0,
                    paddingBottom: () => 0
                };
            }
        }
        return item;
    };

    const addTocItem = (item, tocStyle = {}) => {
        if ('text' in item && typeof item.text === 'string') {
            item.tocItem = true;
            merge(item, tocStyle);
        }
        else if ('stack' in item) {
            const text = item.stack.find(s => 'text' in s);
            if (text && typeof text !== 'string') {
                text.tocItem = true;
                merge(text, tocStyle);
            }
        }
    };
    const handleHeadlineToc = (item) => {
        const tocStyle = {};
        if (item[META]?.[NODE]?.nodeName === 'H1') {
            Object.assign(tocStyle, {
                tocNumberStyle: { bold: true }
            });
        }
        else {
            Object.assign(tocStyle, {
                tocMargin: [10, 0, 0, 0]
            });
        }
        addTocItem(item, tocStyle);
        return item;
    };

    /**
     * Executed before children are parsed.
     * @param item
     */
    const preHandleLazyItem = (item) => {
        if (typeof item !== 'string' && item[META][PDFMAKE]) {
            merge(item, item[META][PDFMAKE] || {});
        }
        if (typeof item[META][PRE_HANDLER] === 'function') {
            return item[META][PRE_HANDLER]?.(item) || null;
        }
        return item;
    };
    /**
     * Executed after children are parsed.
     * @param item
     */
    const postHandleItem = (item) => {
        if (typeof item[META][POST_HANDLER] === 'function') {
            return item[META][POST_HANDLER]?.(item) || null;
        }
        return item;
    };

    let _uid = 0;
    const getUniqueId = (item) => {
        const meta = item[META] || {};
        const __uid = meta[UID];
        const el = item[META]?.[NODE];
        if (__uid) {
            return __uid;
        }
        if (!el) {
            return '#' + (_uid++);
        }
        if (isElement(el)) {
            const id = el.getAttribute('id');
            if (id) {
                return id;
            }
        }
        const nodeName = el.nodeName.toLowerCase();
        // TODO add parent? Or name something else?
        const uid = '#' + nodeName + '-' + (_uid++);
        meta[UID] = uid;
        item[META] = meta;
        return uid;
    };

    // TODO make customizable
    const computeStyleClasses = (item, cascadeClass = []) => {
        const el = item[META]?.[NODE];
        if (!el || !('getAttribute' in el)) {
            return [];
        }
        const cssClass = el.getAttribute('class') || '';
        const cssClasses = [...new Set(// TODO use classList?
            cssClass.split(' ')
                .filter((value) => value)
                .map((value) => '.' + value.trim()))];
        const nodeName = el.nodeName.toLowerCase();
        const parentNodeName = el.parentNode ? el.parentNode.nodeName.toLowerCase() : null;
        const styleNames = [
            nodeName,
        ]
            .concat(cascadeClass.map(cssClass => cssClass + ' ' + nodeName))
            .concat(cssClasses)
            .concat(cssClasses.map(cssClass => nodeName + cssClass));
        if (cssClasses.length > 2) {
            styleNames.push(cssClasses.join('')); // .a.b.c
        }
        if (parentNodeName) {
            styleNames.push(parentNodeName + '>' + nodeName);
        }
        const uniqueId = getUniqueId(item);
        styleNames.push(uniqueId); // Should be the last one
        // TODO improve sort by specificity
        return [...new Set((item.style || []).concat(styleNames))];
    };
    const attrToProps = (item, parentItem) => {
        const el = item[META]?.[NODE];
        if (!el || !('getAttribute' in el))
            return { [META]: { [STYLE]: {} } };
        const cssClasses = computeStyleClasses(item, parentItem?.[META]?.[CLASSES]);
        const nodeName = el.nodeName.toLowerCase();
        const props = {
            [META]: {
                [STYLE]: item[META]?.[STYLE] || {},
                [CLASSES]: (parentItem?.[META]?.[CLASSES] || []).concat(cssClasses),
                ...(item[META] || {})
            },
            style: cssClasses
        };
        for (let i = 0; i < el.attributes.length; i++) {
            const name = el.attributes[i].name;
            const value = el.getAttribute(name)?.trim() || null;
            if (value == null) {
                continue;
            }
            props[META][STYLE][name] = value;
            switch (name) {
                case 'rowspan':
                    props.rowSpan = parseInt(value, 10);
                    break;
                case 'colspan':
                    props.colSpan = parseInt(value, 10);
                    break;
                case 'value':
                    if (nodeName === 'li') {
                        props.counter = parseInt(value, 10);
                    }
                    break;
                case 'start': // ol
                    if (nodeName === 'ol') {
                        props.start = parseInt(value, 10);
                    }
                    break;
                case 'width':
                    if ('image' in item) {
                        props.width = toUnit(value);
                    }
                    break;
                case 'height':
                    if ('image' in item) {
                        props.height = toUnit(value);
                    }
                    break;
                case 'data-fit':
                    if (value === 'true') {
                        const width = el.getAttribute('width');
                        const height = el.getAttribute('height');
                        if (width && height) {
                            props.fit = [toUnit(width), toUnit(height)];
                        }
                    }
                    break;
                case 'data-toc-item':
                    if (value !== 'false') {
                        let toc = {};
                        if (value) {
                            try {
                                toc = JSON.parse(value);
                            }
                            catch (e) {
                                console.warn('Not valid JSON format.', value);
                            }
                        }
                        props[META][POST_HANDLER] = (item) => {
                            addTocItem(item, toc);
                            return item;
                        };
                    }
                    break;
                case 'data-pdfmake':
                    if (value) {
                        try {
                            props[META][PDFMAKE] = JSON.parse(value);
                        }
                        catch (e) {
                            console.warn('Not valid JSON format.', value);
                        }
                    }
                    break;
            }
        }
        return props;
    };

    const inheritStyle = (el, parentItem) => {
        if (!parentItem) {
            return {};
        }
        const styles = parentItem[META]?.[STYLE] || {};
        // TODO check how to proper inherit BG
        const inheritBG = parentItem?.[META]?.[NODE]?.nodeName === 'TR' || parentItem && 'text' in parentItem;
        // TODO what do we want to exclude ?
        const pick = {
            color: el.nodeName !== 'A',
            'font-family': true,
            'font-size': true,
            'font-weight': true,
            'font': true,
            'line-height': true,
            'list-style-type': true,
            'list-style': true,
            'text-align': true,
            background: inheritBG,
            'background-color': inheritBG,
            'font-style': true,
            'font-feature-settings': true,
            'white-space': true,
            'vertical-align': true,
            'opacity': true,
            'text-decoration': true,
        };
        return Object.keys(styles).reduce((p, c) => {
            if (pick[c] || styles[c] === 'inherit') {
                p[c] = styles[c];
            }
            return p;
        }, {});
    };

    // https://stackoverflow.com/a/3627747/5025024
    const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)?.slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('') || '000'}`;
    const parseColor = (color) => {
        if (color[0] === '#') {
            return color;
        }
        if (color.startsWith('rgb')) {
            return rgb2hex(color);
        }
        // TODO parse and take from rootStyle
        if (color.startsWith('var')) {
            return 'black';
        }
        // TODO
        if (color === 'transparent') {
            return 'white';
        }
        return color;
    };

    const getBorderStyle = (value) => {
        const border = value.split(' ');
        const color = border[2] || 'black';
        const borderStyle = border[1] || 'solid';
        const width = toUnit(border[0]);
        return { color, width, borderStyle };
    };
    const colorLineLayout = (props, l) => {
        const layout = typeof props.layout === 'string' ? {} : props.layout || {};
        const colorFn = l === 'h' ? layout.hLineColor : layout.vLineColor;
        const widthFn = l === 'h' ? layout.hLineWidth : layout.vLineWidth;
        const lineWidth = widthFn || (() => 0);
        const lineColor = typeof colorFn === 'function'
            ? colorFn
            : () => typeof colorFn === 'string' ? colorFn : 'black';
        return {
            layout,
            lineWidth,
            lineColor
        };
    };
    const computeBorder = (item, props, directive, value) => {
        const { color, width, borderStyle } = getBorderStyle(value);
        const table = isTable(item);
        const setColumnBorder = (index) => {
            const borderWidth = props[META][BORDER] || [0, 0, 0, 0];
            props.border = item.border || props.border || [false, false, false, false];
            props.borderColor = item.borderColor || props.borderColor || ['black', 'black', 'black', 'black'];
            if (value === 'none') {
                props.border[index] = false;
            }
            else {
                props.border[index] = width > 0;
                props.borderColor[index] = color;
            }
            borderWidth[index] = width;
            props[META][BORDER] = borderWidth;
        };
        switch (directive) {
            case 'border':
                if (table) {
                    props.layout = item.layout || props.layout || {};
                    if (typeof props.layout === 'string') {
                        props.layout = {};
                    }
                    if (value === 'none') {
                        props.layout.hLineWidth = () => 0;
                        props.layout.vLineWidth = () => 0;
                        break;
                    }
                    props.layout.vLineColor = () => color;
                    props.layout.hLineColor = () => color;
                    props.layout.hLineWidth = (i, node) => (i === 0 || i === node.table.body.length) ? width : 0;
                    props.layout.vLineWidth = (i, node) => (i === 0 || i === node.table.widths?.length) ? width : 0;
                    if (borderStyle === 'dashed') {
                        props.layout.hLineStyle = () => ({ dash: { length: 2, space: 2 } });
                        props.layout.vLineStyle = () => ({ dash: { length: 2, space: 2 } });
                    }
                }
                else {
                    setColumnBorder(0);
                    setColumnBorder(1);
                    setColumnBorder(2);
                    setColumnBorder(3);
                }
                break;
            case 'border-bottom':
                if (table) {
                    props.layout = item.layout || props.layout || {};
                    const { layout, lineWidth: hLineWidth, lineColor: hLineColor } = colorLineLayout(props, 'h');
                    layout.hLineWidth = (i, node, columnIndex) => (i === node.table.body.length) ? width : hLineWidth(i, node, columnIndex);
                    layout.hLineColor = (i, node, columnIndex) => (i === node.table.body.length) ? color : hLineColor(i, node, columnIndex);
                    props.layout = layout;
                }
                else {
                    setColumnBorder(POS_BOTTOM);
                }
                break;
            case 'border-top':
                if (table) {
                    props.layout = item.layout || props.layout || {};
                    const { layout, lineWidth: hLineWidth, lineColor: hLineColor } = colorLineLayout(props, 'h');
                    layout.hLineWidth = (i, node, columnIndex) => (i === 0) ? width : hLineWidth(i, node, columnIndex);
                    layout.hLineColor = (i, node, columnIndex) => (i === 0) ? color : hLineColor(i, node, columnIndex);
                    props.layout = layout;
                }
                else {
                    setColumnBorder(POS_TOP);
                }
                break;
            case 'border-right':
                if (table) {
                    props.layout = item.layout || props.layout || {};
                    const { layout, lineWidth: vLineWidth, lineColor: vLineColor } = colorLineLayout(props, 'v');
                    layout.vLineWidth = (i, node, columnIndex) => (node.table.body.length === 1 ? i === node.table.body.length : i % node.table.body.length !== 0) ? width : vLineWidth(i, node, columnIndex);
                    layout.vLineColor = (i, node, columnIndex) => (node.table.body.length === 1 ? i === node.table.body.length : i % node.table.body.length !== 0) ? color : vLineColor(i, node, columnIndex);
                    props.layout = layout;
                }
                else {
                    setColumnBorder(POS_RIGHT);
                }
                break;
            case 'border-left':
                if (table) {
                    props.layout = item.layout || props.layout || {};
                    const { layout, lineWidth: vLineWidth, lineColor: vLineColor } = colorLineLayout(props, 'v');
                    layout.vLineWidth = (i, node, columnIndex) => (node.table.body.length === 1 ? i === 0 : i % node.table.body.length === 0) ? width : vLineWidth(i, node, columnIndex);
                    layout.vLineColor = (i, node, columnIndex) => (node.table.body.length === 1 ? i === 0 : i % node.table.body.length === 0) ? color : vLineColor(i, node, columnIndex);
                    props.layout = layout;
                }
                else {
                    setColumnBorder(POS_LEFT);
                }
                break;
        }
    };

    const computeMargin = (itemProps, item, value, index) => {
        const margin = itemProps[META][MARGIN] || item[META]?.[MARGIN] || [0, 0, 0, 0];
        margin[index] = value;
        itemProps[META][MARGIN] = [...margin];
        itemProps.margin = margin;
        const padding = itemProps[META][PADDING] || item[META]?.[PADDING] || [0, 0, 0, 0];
        const paddingValue = padding[index] || 0;
        itemProps.margin[index] = value + paddingValue;
    };

    const paddings = {
        'padding-top': POS_TOP,
        'padding-left': POS_LEFT,
        'padding-right': POS_RIGHT,
        'padding-bottom': POS_BOTTOM
    };
    const computePadding = (item, props, directive, input) => {
        const index = typeof paddings[directive] !== 'undefined' ? paddings[directive] : -1;
        const value = toUnit(input);
        if (isTable(item)) {
            props.layout = item.layout || props.layout || {};
            if (typeof props.layout === 'string') {
                props.layout = {};
            }
            switch (index) {
                case POS_LEFT:
                    props.layout.paddingLeft = () => value;
                    break;
                case POS_TOP:
                    props.layout.paddingTop = () => value;
                    break;
                case POS_RIGHT:
                    props.layout.paddingRight = () => value;
                    break;
                case POS_BOTTOM:
                    props.layout.paddingBottom = () => value;
                    break;
                default:
                    throw new Error('Unsupported index for "' + directive + '": ' + index);
            }
        }
        else {
            const padding = props[META][PADDING] || item[META]?.[PADDING] || [0, 0, 0, 0];
            padding[index] = value;
            props[META][PADDING] = [...padding];
            if (!isTdOrTh(item)) {
                const margin = props.margin ?? [0, 0, 0, 0];
                props.margin = margin;
                const marginValue = margin[index];
                props.margin[index] = value + marginValue;
            }
        }
    };

    const styleToProps = (item, ctx, styles, rootStyles = {}) => {
        const props = {
            [META]: {
                ...(item[META] || {}),
                [STYLE]: {},
                ...(item[META]?.[STYLE] || {}),
            }
        };
        const meta = props[META];
        const image = isImage(item);
        const table = isTable(item);
        const text = isTextSimple(item);
        const list = isList(item);
        const rootFontSize = toUnit(rootStyles['font-size'] || '16px');
        Object.keys(styles).forEach((key) => {
            const directive = key;
            const value = ('' + styles[key]).trim();
            props[META][STYLE][directive] = value;
            if (typeof ctx.config.styleRule === 'function' && ctx.config.styleRule(directive, value, props)) {
                return;
            }
            switch (directive) {
                case 'padding': {
                    const paddings = expandValueToUnits(value);
                    if (table && paddings !== null) {
                        let layout = props.layout || item.layout || {};
                        if (typeof layout === 'string') {
                            layout = {};
                        }
                        layout.paddingLeft = () => Number(paddings[POS_LEFT]);
                        layout.paddingRight = () => Number(paddings[POS_RIGHT]);
                        layout.paddingTop = (i) => (i === 0) ? Number(paddings[POS_TOP]) : 0;
                        layout.paddingBottom = (i, node) => (i === node.table.body.length - 1) ? Number(paddings[POS_BOTTOM]) : 0;
                        props.layout = layout;
                    }
                    else if (paddings !== null) {
                        computePadding(item, props, 'padding-top', Number(paddings[POS_TOP]));
                        computePadding(item, props, 'padding-left', Number(paddings[POS_LEFT]));
                        computePadding(item, props, 'padding-right', Number(paddings[POS_RIGHT]));
                        computePadding(item, props, 'padding-bottom', Number(paddings[POS_BOTTOM]));
                    }
                    break;
                }
                case 'padding-left':
                case 'padding-top':
                case 'padding-right':
                case 'padding-bottom':
                    computePadding(item, props, directive, value);
                    break;
                case 'border':
                case 'border-bottom':
                case 'border-top':
                case 'border-right':
                case 'border-left':
                    computeBorder(item, props, directive, value);
                    break;
                case 'font-size': {
                    props.fontSize = toUnit(value, rootFontSize);
                    break;
                }
                case 'line-height':
                    if (value !== 'inherit') { // TODO handle inherit
                        props.lineHeight = toUnit(value, rootFontSize);
                    }
                    break;
                case 'letter-spacing':
                    props.characterSpacing = toUnit(value);
                    break;
                case 'text-align':
                    if (['left', 'right', 'justify', 'center'].includes(value)) {
                        props.alignment = value;
                    }
                    break;
                case 'font-feature-settings': {
                    const settings = value.split(',').filter(s => s).map(s => s.replace(/['"]/g, ''));
                    const fontFeatures = item.fontFeatures || props.fontFeatures || [];
                    fontFeatures.push(...settings);
                    props.fontFeatures = fontFeatures;
                    break;
                }
                case 'font-weight':
                    switch (value) {
                        case 'bold':
                            props.bold = true;
                            break;
                        case 'normal':
                            props.bold = false;
                            break;
                    }
                    break;
                case 'text-decoration':
                    switch (value) {
                        case 'underline':
                            props.decoration = 'underline';
                            break;
                        case 'line-through':
                            props.decoration = 'lineThrough';
                            break;
                        case 'overline':
                            props.decoration = 'overline';
                            break;
                    }
                    break;
                case 'text-decoration-color':
                    props.decorationColor = parseColor(value);
                    break;
                case 'text-decoration-style':
                    if (['dashed', 'dotted', 'double', 'wavy'].includes(value)) {
                        props.decorationStyle = value;
                    }
                    break;
                case 'vertical-align':
                    if (value === 'sub') {
                        props.sub = true;
                    }
                    break;
                case 'font-style':
                    switch (value) {
                        case 'italic':
                            props.italics = true;
                            break;
                    }
                    break;
                case 'font-family': {
                    const font = value.split(',').filter(f => !!f).map(f => f.replace(/["']/g, '').trim());
                    if (ctx.fonts) {
                        props.font = Object.keys(ctx.fonts).find(f => font.includes(f)) || ctx.config.defaultFont;
                    }
                    else {
                        props.font = font[0] || ctx.config.defaultFont;
                    }
                    break;
                }
                case 'color':
                    props.color = parseColor(value);
                    break;
                case 'background':
                case 'background-color':
                    if (table) {
                        let layout = item.layout || {};
                        if (typeof layout === 'string') {
                            layout = {};
                        }
                        layout.fillColor = () => parseColor(value);
                        props.layout = layout;
                    }
                    else if (isTdOrTh(item)) {
                        props.fillColor = parseColor(value);
                    }
                    else {
                        props.background = ['fill', parseColor(value)];
                    }
                    break;
                case 'margin': {
                    const margin = expandValueToUnits(value)?.map(value => typeof value === 'string' ? 0 : value);
                    if (margin) {
                        computeMargin(props, item, margin[POS_TOP], POS_TOP);
                        computeMargin(props, item, margin[POS_LEFT], POS_LEFT);
                        computeMargin(props, item, margin[POS_RIGHT], POS_RIGHT);
                        computeMargin(props, item, margin[POS_BOTTOM], POS_BOTTOM);
                    }
                    break;
                }
                case 'margin-left':
                    computeMargin(props, item, toUnit(value), POS_LEFT);
                    break;
                case 'margin-top':
                    computeMargin(props, item, toUnit(value), POS_TOP);
                    break;
                case 'margin-right':
                    computeMargin(props, item, toUnit(value), POS_RIGHT);
                    break;
                case 'margin-bottom':
                    computeMargin(props, item, toUnit(value), POS_BOTTOM);
                    break;
                case 'page-break-before':
                    if (value === 'always') {
                        props.pageBreak = 'before';
                    }
                    break;
                case 'page-break-after':
                    if (value === 'always') {
                        props.pageBreak = 'after';
                    }
                    break;
                case 'position': {
                    // TODO supoprt bottom and right
                    const x = toUnit(styles['left'] || 0);
                    const y = toUnit(styles['top'] || 0);
                    if (value === 'absolute') {
                        props.absolutePosition = {
                            x,
                            y
                        };
                    }
                    else if (value === 'relative') {
                        props.relativePosition = {
                            x,
                            y
                        };
                    }
                    break;
                }
                case 'white-space':
                    if (value === 'pre' && meta[NODE]) {
                        if (text) {
                            props.text = meta[NODE]?.textContent || '';
                        }
                        props.preserveLeadingSpaces = true;
                    }
                    break;
                case 'display':
                    if (value === 'flex') {
                        props[META][POST_HANDLER] = handleColumns;
                    }
                    else if (value === 'none') {
                        props[META][PRE_HANDLER] = () => null;
                    }
                    break;
                case 'opacity':
                    props.opacity = Number(parseFloat(value));
                    break;
                case 'gap':
                    props.columnGap = toUnit(value) / 2; // TODO check
                    break;
                case 'list-style-type':
                case 'list-style':
                    if (list) {
                        props.type = value;
                    }
                    else {
                        props.listType = value;
                    }
                    break;
                case 'width':
                    if (table) {
                        const width = toUnitOrValue(value);
                        if (width !== null) {
                            item.table.widths = [width];
                        }
                    }
                    else if (image) {
                        props.width = toUnit(value);
                    }
                    break;
                case 'height':
                    if (image) {
                        props.height = toUnit(value);
                    }
                    break;
                case 'max-height':
                    if (image) {
                        props.maxHeight = toUnit(value);
                    }
                    break;
                case 'max-width':
                    if (image) {
                        props.maxWidth = toUnit(value);
                    }
                    break;
                case 'min-height':
                    if (image) {
                        props.minHeight = toUnit(value);
                    }
                    break;
                case 'min-width':
                    if (image) {
                        props.minWidth = toUnit(value);
                    }
                    break;
                case 'object-fit':
                    if (value === 'contain' && image) {
                        meta[POST_HANDLER] = handleImg;
                    }
                    break;
            }
        });
        return props;
    };

    /**
     * @param el DOM Element
     */
    const getInlineStyles = (el) => ('getAttribute' in el ? el.getAttribute('style') || '' : '').split(';')
        .map(style => style.trim().split(':'))
        .filter(style => style.length === 2)
        .reduce((style, value) => {
        style[value[0].trim().toLowerCase()] = value[1].trim();
        return style;
    }, {});
    const selectStyles = (selectors, styles) => selectors
        .filter((selector) => styles && styles[selector])
        .reduce((style, selector) => {
        return {
            ...style,
            ...styles[selector]
        };
    }, {});

    /**
     *
     * @param item
     * @param ctx the context
     * @param parentItem? the parent item
     */
    const computeProps = (item, ctx, parentItem) => {
        const el = item[META][NODE];
        const styles = ctx.styles;
        const rootStyles = styles[':root'] || globalStyles()[':root'];
        const attrProps = attrToProps(item, parentItem);
        const selectors = attrProps.style || [];
        const elementStyles = selectStyles(selectors, styles);
        const inheritedStyles = inheritStyle(el, parentItem);
        const cssStyles = Object.assign({}, rootStyles, inheritedStyles, elementStyles, getInlineStyles(el));
        const styleProps = styleToProps(item, ctx, cssStyles, rootStyles);
        return {
            ...styleProps,
            ...attrProps,
            [META]: {
                ...(styleProps[META] || {}),
                ...(attrProps[META] || {}),
                ...(item[META] || {}),
                [STYLE]: {
                    ...(styleProps[META][STYLE] || {}),
                    ...(attrProps[META][STYLE] || {}),
                }
            }
        };
    };

    const collapseMargin = (item, prevItem) => {
        if (isCollapsable(item) && isCollapsable(prevItem)) {
            const prevMargin = prevItem[META]?.[MARGIN] || [0, 0, 0, 0];
            prevItem[META] = { ...(prevItem[META] || {}), [MARGIN]: prevMargin };
            prevItem.margin[POS_BOTTOM] = prevItem[META]?.[PADDING]?.[POS_BOTTOM] || 0;
            const itemMargin = item[META]?.[MARGIN] || [0, 0, 0, 0];
            const marginTop = Math.max(itemMargin[POS_TOP], prevMargin[POS_BOTTOM]);
            itemMargin[POS_TOP] = marginTop;
            prevMargin[POS_BOTTOM] = 0;
            item[META] = { ...(item[META] || {}), [MARGIN]: itemMargin };
            item.margin[POS_TOP] = marginTop + (item[META]?.[PADDING]?.[POS_TOP] || 0);
        }
    };
    const findLastDeep = (ta) => {
        const last = ta.text.at(-1);
        if (isTextArray(last)) {
            return findLastDeep(last);
        }
        return last;
    };
    const findFirstArrayDeep = (ta) => {
        const first = ta.text.at(0);
        if (isTextArray(first)) {
            return findFirstArrayDeep(first);
        }
        return ta.text;
    };
    const collapseWhitespace = (item, nextText) => {
        const prevLastText = findLastDeep(item);
        const nextFirstTextArray = findFirstArrayDeep(nextText);
        if (prevLastText && prevLastText[META]?.[IS_WHITESPACE] && nextFirstTextArray?.[0]?.[META]?.[IS_WHITESPACE]) {
            nextFirstTextArray.shift();
        }
    };

    const WHITESPACE = ' ';
    const addWhitespace = (type) => ({
        text: WHITESPACE,
        [META]: {
            [IS_WHITESPACE]: type
        }
    });

    function isBase64(str) {
        return /^data:image\/(jpeg|png|jpg);base64,/.test(str);
    }
    const parseImg = (el, ctx) => {
        const src = el.getAttribute('src');
        if (!src) {
            return null;
        }
        const name = el.getAttribute('name') || src;
        let image;
        if (isBase64(src)) {
            image = src;
        }
        else if (ctx.images[name]) {
            image = name;
        }
        else {
            ctx.images[src] = name;
            image = name;
        }
        return {
            image,
            [META]: {}
        };
    };

    const setLink = (href, link) => {
        if (typeof link !== 'string') {
            if (href[0] === '#') {
                link.linkToDestination = href.slice(1);
            }
            else {
                link.link = href;
            }
        }
    };
    const linkify = (href, item) => {
        const children = getChildItems(item);
        children.forEach((link) => {
            setLink(href, link);
            linkify(href, link);
        });
    };
    const parseLink = (href) => {
        return {
            text: items => {
                items.forEach((link) => {
                    setLink(href, link);
                    linkify(href, link);
                });
                return items;
            }
        };
    };

    const parseSvg = (el) => {
        // TODO is this okay?
        const svgEl = el.cloneNode(true);
        const width = el.getAttribute('width');
        const height = el.getAttribute('height');
        if (width) {
            svgEl.setAttribute('width', '' + getUnitOrValue(width));
        }
        if (height) {
            svgEl.setAttribute('height', '' + getUnitOrValue(height));
        }
        return {
            svg: svgEl.outerHTML.replace(/\n(\s+)?/g, ''),
        };
    };

    const tableLayoutReset = (defaultBorder = false) => ({
        paddingLeft: () => 0,
        paddingRight: () => 0,
        paddingTop: () => 0,
        paddingBottom: () => 0,
        hLineWidth: () => 0,
        vLineWidth: () => 0,
        defaultBorder
    });
    const spaceColumn = () => ({
        text: '',
        border: [false, false, false, false],
        [META]: { [BORDER]: [0, 0, 0, 0], [PADDING]: [0, 0, 0, 0] }
    });
    const computeHeights = (trs, defaultHeight = 'auto') => {
        const heights = new Array(trs.length).fill(defaultHeight);
        if (Array.isArray(trs)) {
            trs.forEach((tr, i) => {
                if (tr[META]?.[STYLE]?.height) {
                    heights[i] = getUnitOrValue(tr[META]?.[STYLE]?.height || defaultHeight);
                }
            });
        }
        return heights;
    };
    const applyColStylesAndComputeWidth = (longestRow, percentageWidth, rows, colgroup) => {
        const widths = new Array(longestRow).fill(percentageWidth ? '*' : 'auto');
        if (!(colgroup && 'text' in colgroup && Array.isArray(colgroup.text))) {
            return widths;
        }
        colgroup.text.forEach((col, i) => {
            // TODO support other styles and refactor code
            if (typeof col !== 'string') {
                rows.forEach(row => {
                    if (row[i] && typeof row[i] !== 'string') {
                        const item = row[i];
                        if (!item.fillColor && col.fillColor) { // only if not set
                            item.fillColor = col.fillColor;
                        }
                    }
                });
            }
            if (col[META]?.[STYLE]?.width) {
                widths[i] = getUnitOrValue(col[META]?.[STYLE]?.width || '*');
            }
        });
        return widths;
    };
    /**
     * Add a spacer Column if rowspan is defined.
     * Checks the colSpan value
     * @param rows
     * @param longestRow
     */
    function verifyColspanRowspan(rows, longestRow) {
        let rowSpan = [];
        return rows.map(column => {
            rowSpan.forEach(span => {
                column.splice(span.index, 0, {
                    ...spaceColumn(),
                    rowSpan: span.rowSpan - 1
                });
            });
            rowSpan = [];
            let colIndex = 0;
            const columns = column.map((column, index) => {
                if (typeof column !== 'string') {
                    if (column.rowSpan && column.rowSpan > 1) {
                        rowSpan.push({
                            index,
                            rowSpan: column.rowSpan
                        });
                    }
                    if (column.colSpan) {
                        if (column.colSpan > 1 && column.colSpan > (longestRow - colIndex)) {
                            column.colSpan = longestRow - colIndex;
                        }
                        colIndex += column.colSpan;
                    }
                    else {
                        colIndex += 1;
                    }
                }
                return column;
            });
            // Not enough columns ?
            if (longestRow > colIndex) {
                return [
                    ...columns,
                    ...new Array(longestRow - colIndex).fill(null).map(spaceColumn)
                ];
            }
            return columns;
        });
    }
    const withBorder = (td) => typeof td !== 'string' && typeof td.border !== 'undefined';
    const withoutBorder = (td) => typeof td !== 'string' && !td.border;
    const parseTable = () => {
        return {
            table: {
                body: (items, _, item) => {
                    // tbody -> tr
                    const colgroup = items.find(isColgroup)?.stack[0];
                    const tbody = items.filter(item => !isColgroup(item));
                    const trs = tbody.flatMap((item) => 'stack' in item ? item.stack : []);
                    const collapseBorder = item?.[META]?.[STYLE]?.['border-collapse'] === 'collapse';
                    const rows = trs.map((tr) => {
                        const tds = getChildItems(tr);
                        // Inherit border from TR
                        if (collapseBorder && withBorder(tr)) {
                            tds.filter(withoutBorder).forEach(td => {
                                td.border = tr.border;
                                td.borderColor = tr.borderColor;
                                td[META][BORDER] = tr[META][BORDER];
                            });
                        }
                        return tds;
                    });
                    if (rows.length === 0) {
                        return [];
                    }
                    const percentageWidth = String(item?.[META]?.[STYLE]?.['width']).indexOf('%') > -1;
                    const longestRow = rows.reduce((a, b) => a.length <= b.length ? b : a).length;
                    const tdWidths = applyColStylesAndComputeWidth(longestRow, percentageWidth, rows, colgroup);
                    const trHeights = computeHeights(trs);
                    const body = verifyColspanRowspan(rows, longestRow);
                    let table = {
                        body,
                        widths: tdWidths,
                        heights: trHeights
                    };
                    if (!collapseBorder) {
                        const spacing = toUnit(item?.[META]?.[STYLE]?.['border-spacing'] || 2);
                        const countColumns = longestRow * 2 + 1; // Double and the last one
                        const widths = [...tdWidths.flatMap((value) => [spacing, value]), spacing];
                        const heights = [...trHeights.flatMap((value) => [spacing, value]), spacing];
                        const fakeRow = () => new Array(countColumns).fill(null).map(spaceColumn);
                        const incSpan = (column) => {
                            if (typeof column !== 'string' && column.colSpan) {
                                column.colSpan += 2; // next gap and the last one
                            }
                            if (typeof column !== 'string' && column.rowSpan) {
                                column.rowSpan += 2; // next gap and the last one
                            }
                            return column;
                        };
                        const bodyWithGap = [
                            ...body.flatMap(row => [
                                fakeRow(),
                                [...row.flatMap(column => [spaceColumn(), incSpan(column)]), spaceColumn()]
                            ]),
                            fakeRow()
                        ];
                        table = {
                            body: bodyWithGap,
                            widths,
                            heights
                        };
                    }
                    const innerTable = {
                        table,
                        layout: tableLayoutReset(),
                    };
                    return [[innerTable]];
                },
            },
            layout: tableLayoutReset(true),
            [META]: {
                [POST_HANDLER]: handleTable,
            },
        };
    };

    const parseText = (el) => {
        const text = el.textContent;
        if (text === null) {
            return null;
        }
        const keepNewLines = text.replace(/[^\S\r\n]+/, '');
        const trimmedText = text.replace(/\n|\t| +/g, ' ')
            .replace(/^ +/, '')
            .replace(/ +$/, '');
        //.trim() removes also &nbsp;
        const endWithNL = keepNewLines.at(-1) === '\n';
        const startWithNL = keepNewLines[0] === '\n';
        const startWithWhitespace = text[0] === ' ';
        const endWithWhitespace = text.at(-1) === ' ';
        return {
            text: trimmedText,
            [META]: {
                [START_WITH_NEWLINE]: startWithNL,
                [END_WITH_NEWLINE]: endWithNL,
                [IS_NEWLINE]: startWithNL && trimmedText.length === 0,
                [START_WITH_WHITESPACE]: startWithWhitespace,
                [END_WITH_WHITESPACE]: endWithWhitespace,
                [IS_WHITESPACE]: startWithWhitespace && text.length === 1,
            },
        };
    };

    const parseAsHTMLCollection = (el) => ['TABLE', 'TBODY', 'TR', 'COLGROUP', 'COL', 'UL', 'OL', 'SELECT'].includes(el.nodeName) && 'children' in el;
    const stackRegex = /^(address|blockquote|body|center|colgroup|dir|div|dl|fieldset|figure|figcaption|form|h[1-6]|hr|isindex|menu|noframes|noscript|ol|p|pre|table|ul|dd|dt|frameset|li|tbody|td|tfoot|th|thead|tr|html)$/i;
    const isStackItem = (el) => stackRegex.test(el.nodeName);
    const parseChildren = (el, ctx, parentItem) => {
        const items = [];
        const children = parseAsHTMLCollection(el) ? el.children : el.childNodes;
        for (let i = 0; i < children.length; i++) {
            const item = parseByRule(children[i], ctx, parentItem);
            if (item === null) {
                continue;
            }
            const isNewline = !!item[META]?.[IS_NEWLINE];
            const prevItem = items[items.length - 1];
            if (ctx.config.collapseMargin && prevItem) {
                collapseMargin(item, prevItem);
            }
            const prevIsBlock = prevItem && !('text' in prevItem);
            // Skip new lines
            if (isNewline && (items.length === 0 || !children[i + 1] || prevIsBlock)) {
                continue;
            }
            // Block item
            if (!('text' in item)) {
                items.push(item);
                continue;
            }
            const endWithNewLine = !!item[META]?.[END_WITH_NEWLINE];
            const startWithNewLine = !!item[META]?.[START_WITH_NEWLINE];
            const endWithWhiteSpace = !!item[META]?.[END_WITH_WHITESPACE];
            const startWithWhitespace = !!item[META]?.[START_WITH_WHITESPACE];
            const isWhitespace = !!item[META]?.[IS_WHITESPACE];
            const isAbsolutePosition = item?.[META]?.[STYLE]?.position === 'absolute';
            const textItem = Array.isArray(item.text)
                ? item
                : { text: [isNewline || isWhitespace ? addWhitespace('newLine') : item] };
            if (!isNewline && !isWhitespace) {
                // https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
                // add whitespace before
                if (startWithNewLine || startWithWhitespace) {
                    textItem.text.unshift(addWhitespace(startWithNewLine ? 'startWithNewLine' : 'startWithWhitespace'));
                }
                // add whitespace after
                if (endWithNewLine || endWithWhiteSpace) {
                    textItem.text.push(addWhitespace(endWithNewLine ? 'endWithNewLine' : 'endWithWhiteSpace'));
                }
            }
            // Append text to last text element otherwise a new line is created
            if (isTextArray(prevItem)) {
                if (ctx.config.collapseWhitespace) {
                    collapseWhitespace(prevItem, textItem);
                }
                prevItem.text.push(textItem);
            }
            else if (isAbsolutePosition) {
                items.push(textItem);
            }
            else {
                // wrap so the next text items  will be appended to it
                items.push({
                    text: [textItem]
                });
            }
        }
        return items;
    };
    const getNodeRule = (node) => {
        const nodeName = node.nodeName.toLowerCase();
        switch (nodeName) {
            case '#comment':
                return () => null;
            case '#text':
                return parseText;
            default:
                return () => null;
        }
    };
    const getElementRule = (el) => {
        const nodeName = el.nodeName.toLowerCase();
        switch (nodeName) {
            case '#comment':
            case 'option': // see <select>
            case 'script':
            case 'style':
            case 'iframe':
            case 'object':
                return () => null;
            case '#text':
                return parseText;
            case 'a':
                return (el) => {
                    const href = el.getAttribute('href');
                    if (!href) {
                        return parseElement(el);
                    }
                    return parseLink(href);
                };
            case 'br':
                return () => ({
                    text: '\n'
                });
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                return (el) => {
                    const headline = parseElement(el);
                    if (headline === null) {
                        return null;
                    }
                    const meta = headline[META] || {};
                    meta[POST_HANDLER] = handleHeadlineToc;
                    headline[META] = meta;
                    return headline;
                };
            case 'qr-code': // CUSTOM
                return (el) => {
                    const content = el.getAttribute('value');
                    if (!content) {
                        return null;
                    }
                    const sizeAttr = el.getAttribute('data-size');
                    const size = sizeAttr ? toUnit(sizeAttr) : toUnit('128px');
                    return {
                        qr: content,
                        fit: size,
                    };
                };
            case 'toc': // CUSTOM
                return (el) => {
                    const content = el.textContent;
                    if (!content) {
                        return null;
                    }
                    return {
                        toc: {
                            title: {
                                text: content,
                                bold: true,
                                fontSize: toUnit('22px'),
                                margin: [0, 10, 0, 10]
                            },
                        },
                    };
                };
            case 'table':
                return parseTable;
            case 'ul':
                return () => {
                    return {
                        ul: (items) => items
                    };
                };
            case 'ol':
                return () => {
                    return {
                        ol: (items) => items
                    };
                };
            case 'img':
                return parseImg;
            case 'svg':
                return parseSvg;
            case 'hr':
                // TODO find better <hr> alternative?
                return () => {
                    return {
                        table: {
                            widths: ['*'],
                            body: [
                                [''],
                            ]
                        },
                        style: ['hr'],
                    };
                };
            case 'input':
                // TODO acro-form
                return (el) => {
                    if (el instanceof HTMLInputElement) {
                        return {
                            text: 'value' in el ? el.value : '',
                            [META]: {
                                [IS_INPUT]: true
                            }
                        };
                    }
                    return null;
                };
            case 'select':
                // TODO acro-form
                return (el) => {
                    if (el instanceof HTMLSelectElement) {
                        const value = el.options[el.selectedIndex].value;
                        return {
                            text: value,
                            [META]: {
                                [IS_INPUT]: true
                            }
                        };
                    }
                    return null;
                };
            default:
                return parseElement;
        }
    };
    const getItemByRule = (el, ctx) => {
        if (typeof ctx.config.nodeRule === 'function') {
            const result = ctx.config.nodeRule(el, ctx);
            if (result === null) {
                return null;
            }
            else if (result !== undefined) {
                return result;
            }
        }
        if (isElement(el)) { // ELEMENT_NODE
            return getElementRule(el)(el, ctx);
        }
        else if (isNode(el)) { // TEXT_NODE || COMMENT_NODE
            return getNodeRule(el)(el, ctx);
        }
        throw new Error('Unsupported Node Type: ' + el.nodeType);
    };
    const evaluateLazyChildren = (item, ctx) => {
        const el = item[META][NODE];
        if ('stack' in item && typeof item.stack === 'function') {
            const children = parseChildren(el, ctx, item);
            item.stack = item.stack(children, ctx, item);
        }
        else if ('text' in item && typeof item.text === 'function') {
            const children = parseChildren(el, ctx, item);
            item.text = item.text(children.filter(isTextOrLeaf), ctx, item);
        }
        else if ('ul' in item && typeof item.ul === 'function') {
            const children = parseChildren(el, ctx, item);
            item.ul = item.ul(children, ctx, item);
        }
        else if ('ol' in item && typeof item.ol === 'function') {
            const children = parseChildren(el, ctx, item);
            item.ol = item.ol(children, ctx, item);
        }
        else if ('table' in item && typeof item.table.body === 'function') {
            const children = parseChildren(el, ctx, item);
            item.table.body = item.table.body(children, ctx, item);
        }
    };
    const parseLazyChildren = (item, ctx, parentItem) => {
        if (typeof item === 'string') {
            return preHandleLazyItem(item);
        }
        const props = computeProps(item, ctx, parentItem);
        Object.assign(item, props);
        const preHandled = preHandleLazyItem(item);
        if (preHandled === null) {
            return null;
        }
        evaluateLazyChildren(preHandled, ctx);
        return preHandled;
    };
    const processItems = (item, ctx, parentItem) => {
        const withChildren = parseLazyChildren(item, ctx, parentItem);
        if (withChildren === null) {
            return null;
        }
        return postHandleItem(withChildren);
    };
    const parseByRule = (el, ctx, parentItem) => {
        const item = getItemByRule(el, ctx);
        if (item === null) {
            return null;
        }
        // Add ref to NODE
        const meta = item[META] || {};
        meta[NODE] = el;
        item[META] = meta;
        return processItems(item, ctx, parentItem);
    };
    const parseElement = (el) => {
        if (isStackItem(el)) {
            return {
                stack: (items) => items
            };
        }
        return {
            text: (items) => {
                // Return flat
                if (items.length === 1 && 'text' in items[0] && Array.isArray(items[0].text)) {
                    return items[0].text;
                }
                return items;
            }
        };
    };

    const headerFooterContent = (els, ctx) => {
        const elements = {};
        let index = 1;
        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            // Add Images to context
            const images = el.querySelectorAll(':scope img');
            for (let i = 0; i < images.length; i++) {
                parseImg(images[i], ctx);
            }
            const dataPage = el.getAttribute('data-page');
            let page = index;
            if (dataPage) {
                const num = parseInt(dataPage, 10);
                if (['even', 'odd', 'default'].includes(dataPage)) {
                    page = dataPage;
                }
                else if (!isNaN(num)) {
                    page = num;
                }
                else {
                    page = index++;
                }
            }
            else {
                page = index++;
            }
            elements[page] = (data) => {
                return parseChildren(ctx.config.render(el, data), ctx);
            };
        }
        return elements;
    };
    const headerFooter = (currentPage, pageCount, headerOrFooter) => {
        const evenOdd = currentPage % 2 === 0 ? 'even' : 'odd';
        const data = {
            currentPage,
            pageCount
        };
        return headerOrFooter[evenOdd]?.(data) || headerOrFooter[currentPage]?.(data) || headerOrFooter['default']?.(data) || [];
    };

    const simpleRender = (el, data) => {
        const clone = el.cloneNode(true);
        Object.keys(data).forEach(key => {
            const value = data[key];
            clone.querySelectorAll('[data-value="' + key + '"]').forEach(el => {
                el.innerHTML = '' + value;
            });
        });
        return clone;
    };

    const htmlToDom = (html) => {
        if (typeof DOMParser !== 'undefined') {
            const parser = new DOMParser();
            const doc = parser.parseFromString('<body>' + html + '</body>', 'text/html');
            return doc.body;
        }
        else if (typeof document !== 'undefined' && typeof document.createDocumentFragment === 'function') {
            const fragment = document.createDocumentFragment();
            const doc = document.createElement('div');
            doc.innerHTML = html;
            fragment.append(doc);
            return fragment.children[0];
        }
        throw new Error('Could not parse html to DOM. Please use external parser like jsdom.');
    };

    const parse = (input, _config = defaultConfig()) => {
        const root = typeof input === 'string' ? htmlToDom(input) : input;
        if (root?.nodeName === 'TEMPLATE' || root?.nodeName === 'HTML' || root?.nodeName === '#document') {
            return parseTemplate(root, _config);
        }
        const ctx = createContext(_config);
        if (root) {
            const styles = root.querySelectorAll('style');
            const pageStyles = ctx.config.parseCss(styles);
            ctx.setPageStyles(pageStyles);
        }
        const content = root !== null ? parseChildren(root, ctx) : [];
        return {
            info: {
                title: 'PDF Document - created by html2pdfmake',
                creator: 'html2pdfmake',
                producer: 'html2pdfmake',
            },
            content: content,
            images: ctx.images,
            patterns: getPatterns(),
            pageSize: 'A4',
            pageOrientation: 'portrait',
            header: () => [],
            footer: () => [],
            pageMargins: [40, 60, 40, 60]
        };
    };
    const parseTemplate = (template, _config = defaultConfig()) => {
        const ctx = createContext(_config);
        const root = template.nodeName === 'TEMPLATE'
            ? ctx.config.document().importNode(template.content, true)
            : template.nodeName === '#document' ? template.documentElement : template;
        const headers = headerFooterContent(root.querySelectorAll(':scope > header, body > header'), ctx);
        const footers = headerFooterContent(root.querySelectorAll(':scope > footer, body > footer'), ctx);
        const styles = root.querySelectorAll('style');
        const pageStyles = ctx.config.parseCss(styles);
        ctx.setPageStyles(pageStyles);
        const main = root.querySelector('main');
        const title = root.querySelector('title');
        const author = root.querySelector('meta[name="author"]');
        const subject = root.querySelector('meta[name="description"]');
        const keywords = root.querySelector('meta[name="keywords"]');
        const pageSize = root.querySelector('meta[name="page-size"]');
        const pageOrientation = root.querySelector('meta[name="page-orientation"]');
        const pageMargins = root.querySelector('meta[name="page-margins"]');
        const margins = pageMargins?.getAttribute('content')?.split(',').filter(m => m).map(m => parseFloat(m)) || [40, 60, 40, 60];
        const info = {
            title: title?.textContent || 'PDF Document - created by html2pdfmake',
            author: author?.getAttribute('content') || 'html2pdfmake',
            subject: subject?.getAttribute('content') || 'Created by html2pdfmake',
            keywords: keywords?.getAttribute('content') || 'html2pdfmake',
            creator: 'html2pdfmake',
            producer: 'html2pdfmake',
        };
        const content = main !== null ? parseChildren(main, ctx) : [];
        return {
            header(currentPage, pageCount) {
                return headerFooter(currentPage, pageCount, headers);
            },
            footer(currentPage, pageCount) {
                return headerFooter(currentPage, pageCount, footers);
            },
            info,
            content: content,
            images: ctx.images,
            patterns: getPatterns(),
            pageSize: pageSize?.getAttribute('content') || 'A4',
            pageOrientation: pageOrientation?.getAttribute('content') === 'landscape' ? 'landscape' : 'portrait',
            pageMargins: margins,
        };
    };

    exports.getPatterns = getPatterns;
    exports.parse = parse;
    exports.parseTemplate = parseTemplate;
    exports.simpleRender = simpleRender;

    Object.defineProperty(exports, '__esModule', { value: true });

}));
